<template>
  <div class="legalWrapper">
    <div class="legalWrapperBox">
      <div class="topLegalWrap">
        <div
          v-for="(item, itemIndex) in topLegalList"
          :key="itemIndex"
          @click="linkJumps(item.jumpurl)"
          class="topLegal"
        >
          <img :src="item.imgUrl" />
          <div>{{ item.text }}</div>
        </div>
      </div>
      <div
        class="bottomLegalWrap"
        v-for="(content, contentIndex) in bottomLegalList"
        :key="contentIndex"
      >
        <div class="legalTitle">{{ content.name }}</div>
        <div class="legalList">
          <div
            class="legalContent"
            v-for="(list, listIndex) of content.servicesLegalList"
            :key="listIndex"
          >
            <div
              class="listTitle"
              :style="{ backgroundImage: 'url(' + list.topImgUrl + ')' }"
            >
              {{ list.childName }}
            </div>
            <div class="listWrap">
              <div
                class="listItem"
                v-for="(li, liIndex) of list.childList"
                :key="liIndex"
                @click="linkJumps(li.jumpUrl)"
              >
                {{ li.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aiLegal",
  data() {
    return {
      topLegalList: [
        {
          text: "合同自动生成",
          jumpurl: "https://www.lawback.com/generateContract",
          imgUrl: require("@/assets/service/jumback/smartLegal/topLegal1.png"),
        },
        {
          text: "FTA税率查询",
          jumpurl: "https://www.lawback.com/ftaService",
          imgUrl: require("@/assets/service/jumback/smartLegal/topLegal2.png"),
        },
        {
          text: "RCEP税率查询",
          jumpurl: "https://www.lawback.com/RCEPQuery",
          imgUrl: require("@/assets/service/jumback/smartLegal/topLegal3.png"),
        },
        {
          text: "诉状自动生成",
          jumpurl: "https://qzsc.sd12368.gov.cn/#/znfz/znszHome?fybh=1850",
          imgUrl: require("@/assets/service/jumback/smartLegal/topLegal4.png"),
        },
      ],
      bottomLegalList: [
        {
          name: "专业服务",
          servicesLegalList: [
            {
              childName: "税务服务",
              topImgUrl: require("@/assets/service/jumback/smartLegal/service1.png"),
              childList: [
                {
                  text: "12366智能咨询",
                  jumpUrl: "https://12366.chinatax.gov.cn/xiaohui/",
                },
                {
                  text: "办税地图",
                  jumpUrl: "https://12366.chinatax.gov.cn/bsfw/bsdt/",
                },
                {
                  text: "发票查询",
                  jumpUrl: "https://inv-veri.chinatax.gov.cn/",
                },
                {
                  text: "网上办税",
                  jumpUrl:
                    "https://etax.qingdao.chinatax.gov.cn:7553/sso/login?service=https%3A%2F%2Fetax.qingdao.chinatax.gov.cn%2Fportal%2F",
                },
                {
                  text: "国家税务总局青岛市税务局",
                  jumpUrl: "http://qingdao.chinatax.gov.cn/",
                },
              ],
            },
            {
              childName: "会计服务",
              topImgUrl: require("@/assets/service/jumback/smartLegal/service2.png"),
              childList: [
                {
                  text: "会计人员服务平台",
                  jumpUrl: "http://qdcz.qingdao.gov.cn/",
                },
                {
                  text: "电子票据查验平台",
                  jumpUrl:
                    "http://120.221.95.28:18080/billcheck/html/index.html#/home",
                },
                {
                  text: "青岛市财政局",
                  jumpUrl: "http://qdcz.qingdao.gov.cn/",
                },
              ],
            },
            {
              childName: "资产评估服务",
              topImgUrl: require("@/assets/service/jumback/smartLegal/service3.png"),
              childList: [
                {
                  text: "审计服务咨询",
                  jumpUrl: "http://zccx.qingdao.gov.cn/pcSite/index.html",
                },
                {
                  text: "青岛市审计局",
                  jumpUrl: "http://sj.qingdao.gov.cn/",
                },
              ],
            },
            {
              childName: "知识产权服务",
              topImgUrl: require("@/assets/service/jumback/smartLegal/service4.png"),
              childList: [
                {
                  text: "中国电子专利申请",
                  jumpUrl: "http://cponline.cnipa.gov.cn/",
                },
                {
                  text: "技术交易服务",
                  jumpUrl: "https://www.keyibao.com/html/kybinfo.shtml",
                },
                {
                  text: "山东省知识产权公共服务平台",
                  jumpUrl: "http://www.sdips.com.cn/",
                },
              ],
            },
            {
              childName: "贸促服务",
              topImgUrl: require("@/assets/service/jumback/smartLegal/service5.png"),
              childList: [
                {
                  text: "网上贸易调解",
                  jumpUrl: "https://adr.ccpit.org/",
                },
                {
                  text: "重点国别（地区）知识产权信息选编",
                  jumpUrl: "https://www.ctils.com/categories/40/articles",
                },
                {
                  text: "“一带一路”国别法律研究丛书",
                  jumpUrl: "https://www.ctils.com/books/list",
                },
                {
                  text: "贸法通",
                  jumpUrl: "https://www.ctils.com/",
                },
              ],
            },
          ],
        },
        {
          name: "司法&行政",
          servicesLegalList: [
            {
              childName: "法院",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration1.png"),
              childList: [
                {
                  text: "诉讼服务",
                  jumpUrl: "https://sd12368.gov.cn/login",
                },
                {
                  text: "青岛海事法院",
                  jumpUrl: "http://qdhsfy.sdcourt.gov.cn/",
                },
                {
                  text: "阳光司法",
                  jumpUrl: "http://qdzy.sdcourt.gov.cn/",
                },
                {
                  text: "青岛市中级人民法院",
                  jumpUrl: "http://qdzy.sdcourt.gov.cn/",
                },
              ],
            },
            {
              childName: "检察院",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration2.png"),
              childList: [
                {
                  text: "12309服务中心",
                  jumpUrl: "https://www.12309.gov.cn/",
                },
                {
                  text: "胶州市人民检察院",
                  jumpUrl: "http://www.jiaozhou.qdjcy.gov.cn/",
                },
                {
                  text: "青岛市人民检察院",
                  jumpUrl: "http://www.qdjcy.gov.cn/",
                },
              ],
            },
            {
              childName: "公安",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration3.png"),
              childList: [
                {
                  text: "办事服务",
                  jumpUrl:
                    "http://qdzwfw.sd.gov.cn/qd/icity/serviceDept-info?id=QD370200GAJ",
                },
                {
                  text: "公章查询",
                  jumpUrl: "http://qd.sdguodun.com:9300/wechat/dist/index.html",
                },
                {
                  text: "青岛市公安局",
                  jumpUrl: "http://police.qingdao.gov.cn/",
                },
              ],
            },
            {
              childName: "司法局",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration4.png"),
              childList: [
                {
                  text: "办事服务",
                  jumpUrl:
                    "http://qdzwfw.sd.gov.cn/qd/icity/serviceDept-info?id=QD370200SFJ",
                },
                {
                  text: "12348山东法网",
                  jumpUrl: "http://sd.12348.gov.cn/channels/ch00630/",
                },
                {
                  text: "青岛市司法局",
                  jumpUrl: "http://qdsf.qingdao.gov.cn/",
                },
              ],
            },
            {
              childName: "人社局",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration5.png"),
              childList: [
                {
                  text: "办事大厅",
                  jumpUrl: "http://hrsswb.qingdao.gov.cn/",
                },
                {
                  text: "劳动人事争议在线调解服务",
                  jumpUrl: "http://223.71.147.149/portal/",
                },
                {
                  text: "12333智能查询",
                  jumpUrl: "http://120.221.95.60:9000/qdrsznwd/index",
                },
                {
                  text: "贷款资格查询",
                  jumpUrl:
                    "http://12333.qingdao.gov.cn/wssbggjy3/kscx/ggjy/f30020901/index.action?entrance=1",
                },
                {
                  text: "青岛市人社局",
                  jumpUrl: "http://hrss.qingdao.gov.cn/",
                },
              ],
            },
            {
              childName: "海关",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration6.png"),
              childList: [
                {
                  text: "海关总署服务大厅",
                  jumpUrl: "http://online.customs.gov.cn/",
                },
                {
                  text: "青岛海关",
                  jumpUrl: "http://qingdao.customs.gov.cn/",
                },
              ],
            },
            {
              childName: "税务局",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration7.png"),
              childList: [
                {
                  text: "青岛市税务局",
                  jumpUrl: "http://qingdao.chinatax.gov.cn/",
                },
              ],
            },
            {
              childName: "财政局",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration8.png"),
              childList: [
                {
                  text: "青岛市财政局",
                  jumpUrl: "http://qdcz.qingdao.gov.cn/",
                },
              ],
            },
            {
              childName: "总工会",
              topImgUrl: require("@/assets/service/jumback/smartLegal/administration9.png"),
              childList: [
                {
                  text: "青岛市总工会",
                  jumpUrl: "https://www.qdszgh.cn/",
                },
              ],
            },
          ],
        },
        {
          name: "法务资源",
          servicesLegalList: [
            {
              childName: "法务资源（一）",
              topImgUrl: require("@/assets/service/jumback/smartLegal/affairs1.png"),
              childList: [
                {
                  text: "全球法律",
                  jumpUrl: "http://policy.mofcom.gov.cn/law/index.shtml",
                },
                {
                  text: "中国裁判文书网",
                  jumpUrl: "https://wenshu.court.gov.cn/",
                },
                {
                  text: "司法行政案例库",
                  jumpUrl: "http://alk.12348.gov.cn/",
                },
                {
                  text: "重点国别（地区）知识产权信息选编",
                  jumpUrl: "https://www.ctils.com/categories/40/articles",
                },
                {
                  text: "“一带一路”国别法律研究丛书",
                  jumpUrl: "https://www.ctils.com/books/list",
                },
              ],
            },
            {
              childName: "法务资源（二）",
              topImgUrl: require("@/assets/service/jumback/smartLegal/affairs2.png"),
              childList: [
                {
                  text: "中国庭审公开网",
                  jumpUrl: "http://tingshen.court.gov.cn/",
                },
                {
                  text: "海事案例库",
                  jumpUrl: "https://haishi.faxin.cn/index.aspx",
                },
                {
                  text: "中国自由贸易区服务网",
                  jumpUrl: "http://fta.mofcom.gov.cn/index.shtml",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    linkJumps(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped lang="less">
.legalWrapperBox {
  width: 1200px;
  margin: 0 auto;
}
.topLegalWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.topLegal {
  width: 285px;
  height: 230px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0px 0px 35px 0px rgba(176, 176, 176, 0.27);
  cursor: pointer;
  img {
    display: block;
    width: 245px;
    height: 160px;
  }
  div {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    line-height: 22px;
    text-align: center;
    margin-top: 15px;
  }
}
.bottomLegalWrap {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 30px;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 30px;
  &:after {
    content: "";
    display: block;
    width: 3px;
    height: 17px;
    background: #2c68ff;
    position: absolute;
    top: 32px;
    left: 0;
  }
}
.legalTitle {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}
.legalList {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}
.legalContent {
  width: 360px;
  height: 280px;
  background: #ffffff;
  margin-right: 30px;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
  border-radius: 8px;
  margin-top: 30px;
  &:nth-child(3n) {
    margin-right: 0;
  }
}
.listTitle {
  width: 100%;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  padding-left: 30px;
  background-size: cover;
  box-sizing: border-box;
}
.listWrap {
  padding: 0 30px;
  box-sizing: border-box;
}
.listItem {
  font-size: 16px;
  color: #666666;
  margin-top: 18px;
  font-weight: 400;
  cursor: pointer;
}
</style>